import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/abdullatieffathoni/Code/javascript/tds-web/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ImageCard = makeShortcode("ImageCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p><inlineCode parentName="p">{`Footer`}</inlineCode>{` merupakan sebuah struktur yang terdiri dari banyak unsur UI terletak di bagian paling bawah sebuah halaman, digunakan untuk membantu user dengan informasi yang tertulis sekaligus memaksimalkan keterlibatan user.`}</p>
    </PageDescription>
    <h2>{`Example`}</h2>
    <h3>{`Bhinneka B2C`}</h3>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={12} colLg={12} noGutterSm mdxType="Column">
    <ImageCard title="Desktop" titleColor="dark" aspectRatio="16:9" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1152px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "56.25%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABX0lEQVQoz62Sy07DMBBF8+vlDxB8ADvErmLNHhBig1R2RGnejuMmdhPnncvYbSRAIFFBpKO59own4/E4fphgS/jfsA3ikzBnnDjlyLhAmuVgRHqEZQI831kyfmBZfyUj8l2JhM45RalQ1TWKsoRUCqWUpCUU6aZpLOPQYR57NG1r1+3RGrQmyA5aQzAOR1DmcZrQ9QO6rkc/jOjJDmSnaQbmGW6ssPGKg89AscZlvpmEkdL1EL5s4GR5gSjJ6JqCypYIqA85aVVpe/VSKjy8Mtw9x6h0Z9HtYOPf3C1SaoUXRHD9CLxQh4ScEiVM2GBBmzmxrzswXsD4TUumcbR+VVELqKT7xyesVme4ur7B+eUF1utbW5BNaIIKWaFUNeRef6rEUOne/mDZX1B1iz3Fe4lAxIR9GCeMGVWXw7z2gln/hiXWC1M7eqZ1jv/DDJ5CEBnSwxwa8Vf8D/pfEn7kHamONlTiDfFpAAAAAElFTkSuQmCC')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/c467d752ccf4dedd27d8a012e4f81568/3cbba/b2c-desktop.png",
              "srcSet": ["/static/c467d752ccf4dedd27d8a012e4f81568/7fc1e/b2c-desktop.png 288w", "/static/c467d752ccf4dedd27d8a012e4f81568/a5df1/b2c-desktop.png 576w", "/static/c467d752ccf4dedd27d8a012e4f81568/3cbba/b2c-desktop.png 1152w", "/static/c467d752ccf4dedd27d8a012e4f81568/60356/b2c-desktop.png 1360w"],
              "sizes": "(max-width: 1152px) 100vw, 1152px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>
    <Row className="image-card-group" mdxType="Row">
      <Column colMd={4} colLg={4} noGutterSm mdxType="Column">
    <ImageCard title="Mobile Web" titleColor="dark" aspectRatio="1:2" mdxType="ImageCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "540px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "200%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAoCAYAAAD+MdrbAAAACXBIWXMAAAsSAAALEgHS3X78AAADEUlEQVRIx+1WS24TQRDNydix4CwsWIUNFwFxBM4QhQVRFggSAU4ISezMjD3//9/z8aOqxm0cJY5DlAUSGempu6urX1d1vemZHWNm4zGx80T4DxPqU+vBJOtrd3igDJphEcy/hiJlXIvQdn24fgjXYwRwFNxAxsqmWi+IZM21CFWHd8qLkpx9gWU7CMMIns8EHuIkQRCENPZXdiwWqKoa2q2EZEzSDKbJYxOu66HrOrRtS+jW0KJpGvRExk/ZtKu0b0SY5QWKskRObUltlucDshxplkk/5zFlklGk1tv38I+/waAj0pekN1LmZ0G7K/DT9gNkrh9sdRThx8tXMPb2MQ0T6PrsJmGU5KjmHcqqGVA3KKq52B2KIssrsrU0N0c5b5GWNcK02FyUMM6IoEEuZB3qZiG2w89HOP5+ijgrZcN83hMZb9bK/J2EvCChhTPLhe34JItA+pbDlffgUaqFrqGLI3DyUbKNkFJK81IWm0TEerMcT2ATjs5mODw4oYinGGkJaTGFIaK+5dULohRhFCMgnbGEWpJN1/cknwFAj913X/Fsdx8v3nzE89d7GI090azS4rUImTAraomQ006pCIysqKQg3Gp2gp9aiF96hItp/CfCTSlzFbkoxRJMODo5w+j0jPo15k2HRsQ9CJ4VsLXKpu3DMB3BhPTFknH9iPwcTE0XY20m7b2qzBFy2oy8nEurGTOMrwwaL20EbnnzrRFKyktnTtcLEky0KS7GGvVjsYlWH0LIQub0XZ8qT3MmaZGL9WBCBotaLeBzVHb2Yd+thOrsVGp8i1xOdJxfTMRPnbE63zsJOZ3LiSF6ZF3ZVN04HTRp0Ss40QcfrjpvfkVj3njjfchOameVdr6sKNtKrrIIv1rZ7yUbddhcYY/uOo7YdAIcnOj4cnSM6cxa+W0lVFFxalFSCLG8claAD59GOD2fyPuuinMnIS+umx4RXZoqOrb5YSIajOJUouVqs6TEd1NR2Mh3Hh8434EK62Nrza7meM3GDz23mz7mw5y1+qCv2zd+6B/lV+Tp//A/IPwNKqylrkkdA1YAAAAASUVORK5CYII=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Square",
              "title": "Square",
              "src": "/static/36f6ed03710f30be976e40cd6cfc33a7/674c8/b2c-mobile.png",
              "srcSet": ["/static/36f6ed03710f30be976e40cd6cfc33a7/7fc1e/b2c-mobile.png 288w", "/static/36f6ed03710f30be976e40cd6cfc33a7/674c8/b2c-mobile.png 540w"],
              "sizes": "(max-width: 540px) 100vw, 540px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy"
            }}></img>{`
    `}</span>
  </ImageCard>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      